<template>
  <div class="h-full w-full relative">
    <div
      v-if="userIdSelect"
      class="w-full h-[90px] flex items-center justify-between relative border-b border-b-ems-gray700 px-[10px]"
    >
      <div class="flex items-center justify-center space-x-[23px] h-full">
        <div
          :class="`rounded-full w-[64px] h-[64px]  flex justify-center items-center relative flex-shrink-0`"
        >
          <div
            class="rounded-full w-[64px] h-[64px] flex justify-center items-center overflow-hidden flex-shrink-0"
          >
            <img alt="avatar" :src="userIdSelect.avatar" class="w-ful h-auto" />
          </div>
          <div
            class="h-[16px] w-[16px] border-2 rounded-full border-ems-tag1 bg-ems-boTro4_600 absolute bottom-0 right-0"
          ></div>
        </div>
        <div class="space-y-[8px]">
          <p class="text-white font-semibold text-[16px]">
            {{ userIdSelect.userName }}
          </p>
          <p class="text-ems-gray200 font-semibold text-[12px]">
            {{ t('coordinator.online') }}
          </p>
        </div>
      </div>
      <img
        alt="close"
        src="@/static/img/icon/close.png"
        class="cursor-pointer"
        @click="handleClose"
      />
    </div>
    <div
      :class="`custom-scroll-map-${keyClass}`"
      class="custom-scroll-map overflow-auto hidden-scroll py-2 mb-[20px]"
      :style="{ maxHeight: `${maxHeight}px` }"
    >
      <div
        v-if="listHistoryMess && listHistoryMess.messages"
        v-for="(mess, indexMess) in reversedMessages"
        :key="indexMess"
        class="relative"
      >
        <div
          v-if="mess.senderId === idUser"
          class="w-full flex items-center justify-end space-x-2 cursor-pointer mt-1 min-h-[50px] pr-2"
          @mouseenter="handleMouseEnter(event, indexMess)"
          @mouseleave="handleMouseLeave(event, indexMess)"
        >
          <div v-if="isHovered === indexMess" class="flex space-x-[9px]">
            <div
              class="h-[38px] w-[38px] rounded-full bg-ems-gray600 flex justify-center items-center"
              @click="handleDeleteMess(mess)"
            >
              <img alt="delete" src="@/static/img/icon/delete.svg" />
            </div>
            <div
              class="h-[38px] w-[38px] rounded-full bg-ems-gray600 flex justify-center items-center"
            >
              <img alt="share" src="@/static/img/icon/share.svg" />
            </div>
          </div>
          <div class="flex items-center">
            <TextMess
              v-if="mess.messageType === 'text'"
              :forwarded="mess.forwarded"
              :forwardFrom="mess.forwardFrom"
              :content="mess.content"
              :isMyAccount="true"
            />
            <div
              v-else-if="mess.messageType === 'file'"
              class="max-w-[274px] h-max rounded-[20px]"
            >
              <ImageMess
                v-if="mess.mediaType === 'image'"
                :forwarded="mess.forwarded"
                :forwardFrom="mess.forwardFrom"
                :url="mess.url"
                :isMyAccount="true"
              />
              <VideoMess
                v-else-if="mess.mediaType === 'video'"
                :forwarded="mess.forwarded"
                :forwardFrom="mess.forwardFrom"
                :url="mess.url"
                :isMyAccount="true"
              />
              <AudioMess
                v-else-if="mess.mediaType.includes('audio')"
                :mess="mess"
                @handlePause="handlePause(mess)"
                @handlePlay="handlePlay(mess)"
                :isPlayingId="isPlayingId"
                :forwarded="mess.forwarded"
                :forwardFrom="mess.forwardFrom"
                :audioPlayer="audioPlayer"
                :isMyAccount="true"
                :key-class="keyClass"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="w-full flex items-center justify-start space-x-2 min-h-[50px] mt-1 cursor-pointer pl-2"
          @mouseenter="handleMouseEnter(event, indexMess)"
          @mouseleave="handleMouseLeave(event, indexMess)"
        >
          <TextMess
            v-if="mess.messageType === 'text'"
            :forwarded="mess.forwarded"
            :forwardFrom="mess.forwardFrom"
            :content="mess.content"
            :isMyAccount="false"
          />
          <div
            v-if="mess.messageType === 'file'"
            class="max-w-[274px] h-max rounded-[20px]"
          >
            <ImageMess
              v-if="mess.mediaType === 'image'"
              :forwarded="mess.forwarded"
              :forwardFrom="mess.forwardFrom"
              :url="mess.url"
              :isMyAccount="false"
            />
            <VideoMess
              v-else-if="mess.mediaType === 'video'"
              :forwarded="mess.forwarded"
              :forwardFrom="mess.forwardFrom"
              :url="mess.url"
              :isMyAccount="false"
            />
            <AudioMess
              v-else-if="mess.mediaType.includes('audio')"
              :mess="mess"
              @handlePause="handlePause(mess)"
              @handlePlay="handlePlay(mess)"
              :isPlayingId="isPlayingId"
              :forwarded="mess.forwarded"
              :forwardFrom="mess.forwardFrom"
              :audioPlayer="audioPlayer"
              :isMyAccount="false"
              :key-class="keyClass"
            />
          </div>
          <div v-if="isHovered === indexMess" class="flex space-x-[9px]">
            <div
              class="h-[38px] w-[38px] rounded-full bg-ems-gray600 flex justify-center items-center"
              @click="handleDeleteMess(mess)"
            >
              <img alt="delete" src="@/static/img/icon/delete.svg" />
            </div>
            <div
              class="h-[38px] w-[38px] rounded-full bg-ems-gray600 flex justify-center items-center"
            >
              <img alt="share" src="@/static/img/icon/share.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-full h-max flex items-center px-[16px] h-[60px] overflow-hidden absolute bottom-0"
    >
      <img
        v-if="isRecording"
        alt="add"
        src="@/static/img/icon/close-micro.svg"
        class="cursor-pointer rounded-full"
        @mouseup="stopRecording"
      />
      <a-dropdown :trigger="['click']" v-else 
      overlayClassName="overLayDropDown"
      >
        <img
          alt="add"
          src="@/static/img/icon/phone-add.svg"
          class="cursor-pointer rounded-full"
          @click.prevent
        />
        <template #overlay>
          <div>
            <div
              v-for="item in items"
              :key="item.type"
              @click="handleClickUpload(item.type)"
              @mouseenter="hoveredItem = item.type"
              @mouseleave="hoveredItem = ''"
              :class="[
                hoveredItem === item.type ? 'bg-ems-main2' : 'bg-ems-gray800',
                'flex cursor-pointer h-[39px] w-[168px] flex items-center',
              ]"
            >
              <img :src="item.icon" alt="icon" class="pl-[8px] pr-[12px]" />
              <p
                :class="[
                  hoveredItem === item.type
                    ? 'font-semibold text-white'
                    : 'font-normal text-ems-gray300',
                  'text-[16px]',
                ]"
              >
                {{ item.text }}
              </p>
            </div>
          </div>
        </template>
      </a-dropdown>
      <div class="w-full relative">
        <a-input
          v-model:value="userName"
          :placeholder="isRecording ? '' : $t('coordinator.enter_messages')"
          class="bg-ems-gray600 border-none h-[45px] rounded-[80px] ml-[13px] text-white"
          @input="hasInput = $event.target.value.trim() !== ''"
          @keyup.enter="handleSendMessages"
        >
        </a-input>
        <div
          v-if="isRecording"
          class="flex space-x-[10px] items-center justify-center overflow-hidden absolute w-full top-0 left-[13px] h-[50px]"
        >
          <div v-for="(voice, entryIndex) in volumeArray" :key="entryIndex">
            <div
              class="min-h-[15px] w-[5px] rounded-full bg-ems-gray500 max-h-[40px]"
              :style="{ height: voice * 2 + 'px' }"
            ></div>
          </div>
          <p class="text-ems-main2">{{ formattedTime }}</p>
        </div>
      </div>
      <div
        class="bg-ems-main2 w-[38px] h-[38px] rounded-full flex justify-center items-center flex-shrink-0 ml-[20px]"
      >
        <img
          v-if="hasInput || isRecording"
          alt="add"
          src="@/static/img/icon/send.svg"
          class="h-[20px] cursor-pointer"
          @click="handleSendMessages"
        />
        <img
          v-else
          alt="add"
          src="@/static/img/icon/mic-open.svg"
          class="h-[24px] cursor-pointer"
          @mousedown="startRecording"
        />
      </div>
    </div>
    <ModalDelete
      :isPopupDelete="isShowDelete.active"
      @handleConfirm="handleConfirm"
      @handleCancel="handleCancel"
    />
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  watch,
  nextTick,
  onMounted,
  onUnmounted,
  defineProps,
} from 'vue';
import { useStore } from 'vuex';
import ModalDelete from './modal-delete.vue';
import { useI18n } from 'vue-i18n';
import VueTypes from 'vue-types';
import { DataService } from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
import TextMess from '../../../strategic-coordination/components/walkie-talkie/tab/components/type-messages/text-mess.vue';
import ImageMess from '../../../strategic-coordination/components/walkie-talkie/tab/components/type-messages/image-mess.vue';
import VideoMess from '../../../strategic-coordination/components/walkie-talkie/tab/components/type-messages/video-mess.vue';
import AudioMess from '../../../strategic-coordination/components/walkie-talkie/tab/components/type-messages/audio-mess.vue';
const { state, dispatch } = useStore();
const isPlayingId = ref('');
const isHovered = ref(null);
const audioPlayer = ref(null);
const itemDelete = ref(null);
const popup = ref(null);
const itemValue = computed(() => state.coordinator.itemValue || '');
const devices = computed(() => state.deviceManagement.devices);
const hoveredItem = ref('');
const userName = ref('');
const urlImage = ref('');
const hasInput = ref(false);
const mediaRecorder = ref(null);
const audioChunks = ref([]);
const isRecording = ref(false);
const audioURL = ref('');
const audioFile = ref(null);
const currentPlayingMess = ref(null);
const currentCountdownInterval = ref(null);
const isSendFile = ref(false);
const mediaType = ref('');
const seconds = ref(0);
const userIdSelect = ref(null);
let analyser = null;
const test = ref(null);
const minutes = ref(0);
const volumeArray = ref(Array(10).fill(0));
let intervalId;
const listHistoryMess = computed(() => state.coordinator.historyMessages);
const isShowDelete = computed(() => state.coordinator.isShowDeleted);
const isLoadingMess = computed(() => state.coordinator.isLoadingMess);
const { t } = useI18n();
const props = defineProps({
  name: VueTypes.string.def(''),
  message: VueTypes.string.def(''),
  idTrinhsat: VueTypes.string.def(''),
  maxHeight: VueTypes.number.def(600),
  keyClass : VueTypes.string.def(''),
});
const reversedMessages = computed(() => {
  return listHistoryMess.value && listHistoryMess.value.messages
    ? [...listHistoryMess.value.messages].reverse()
    : [];
});

const scrollToBottom = () => {
  var container = document.querySelector(`.custom-scroll-map-${props.keyClass}`);
  if (container) {
    var scrollHeight = container.scrollHeight;
    container.scrollTop = scrollHeight;
  }
};
watch(
  () => props.idTrinhsat,
  (newId, oldId) => {
    fetchHistoryMess(newId);
    userIdSelect.value = devices.value.find(
      (device) => device.userId === newId
    );
  }
);
onMounted(() => {
  fetchHistoryMess(props.idTrinhsat);
  userIdSelect.value = devices.value.find(
    (device) => device.userId === props.idTrinhsat
  );
  nextTick(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  });
});

const fetchHistoryMess = (id) => {
  dispatch('coordinator/getDialogsId', id).then((dialogId) => {
    const isChated = !!dialogId;
    dispatch('coordinator/getMessagesByDialogs', {
      dialogId: dialogId,
      dialogType: 1,
      isChated: isChated,
    });
  });
};
watch(reversedMessages, () => {
  nextTick(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  });
});
const idUser = computed(() => state.auth.idUser);
function resetPreviousAudio() {
  if (currentPlayingMess.value) {
    const previousCountdownDisplay = document.getElementById(
      `countdown${props.keyClass}${currentPlayingMess.value.messageIdStr}`
    );
    if (previousCountdownDisplay) {
      previousCountdownDisplay.textContent = formatTimeCountDown(
        currentPlayingMess.value.length
      );
    }

    if (audioPlayer.value) {
      audioPlayer.value.pause();
    }

    if (currentCountdownInterval.value) {
      clearInterval(currentCountdownInterval.value);
    }
  }
}
const handlePlay = async (mess) => {
  resetPreviousAudio();
  await nextTick();
  const audio = new Audio(mess.url);
  audioPlayer.value = audio;
  audio.currentTime = 0;
  audio.play();
  startCountdown(mess.length, mess);
  currentPlayingMess.value = mess;
  isPlayingId.value = mess.messageIdStr;
};
const handlePause = async (mess) => {
  if (audioPlayer.value) {
    audioPlayer.value.pause();
  }
  if (currentCountdownInterval.value) {
    clearInterval(currentCountdownInterval.value);
  }
  currentPlayingMess.value = null;
  isPlayingId.value = '';
};
function startCountdown(durationInSeconds, mess) {
  const countdownDisplay = document.getElementById(`countdown${props.keyClass}${mess.messageIdStr}`);
  const initialCountdownDisplayContent = countdownDisplay.textContent;
  countdownDisplay.textContent = formatTimeCountDown(durationInSeconds);

  currentCountdownInterval.value = setInterval(() => {
    durationInSeconds--;
    countdownDisplay.textContent = formatTimeCountDown(durationInSeconds);
    if (durationInSeconds <= 0) {
      clearInterval(currentCountdownInterval.value);
      isPlayingId.value = '';
      handlePause(mess);
      countdownDisplay.textContent = initialCountdownDisplayContent;
    }
  }, 1000);
}
function formatTimeCountDown(seconds) {
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0');
  const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
  return `${minutes}:${remainingSeconds}`;
}

const handleMouseEnter = (event, indexMess) => {
  isHovered.value = indexMess;
};
const handleMouseLeave = (event, indexMess) => {
  isHovered.value = null;
};
const handleDeleteMess = (item) => {
  let payload = {
    active: true,
    type: 'single',
  };
  dispatch('coordinator/isShowDelete', payload);
  itemDelete.value = item;
};
const handleCancel = () => {
  let payload = {
    active: false,
    type: 'single',
  };
  dispatch('coordinator/isShowDelete', payload);
};

const handleConfirm = () => {
  if (isShowDelete.value.type === 'single') {
    let payload = {
      dialogId: itemDelete.value.dialogId,
      dialogType: itemDelete.value.dialogType,
      messageIdStr: itemDelete.value.messageIdStr,
    };
    dispatch('coordinator/deleteSingleMess', payload).then(() => {
      let payload = {
        active: false,
        type: 'single',
      };
      dispatch('coordinator/isShowDelete', payload);
    });
  } else {
    let payload = {
      dialogId: itemValue.value.dialogId,
      dialogType: itemValue.value.dialogType,
    };
    dispatch('coordinator/deleteAllMess', payload).then(() => {
      let payload = {
        active: false,
        type: 'single',
      };
      dispatch('coordinator/isShowDelete', payload);
    });
  }
};

const items = [
  {
    type: 'video',
    icon: require('@/static/img/icon/send-video.svg'),
    text: t('coordinator.send_video'),
  },
  {
    type: 'image',
    icon: require('@/static/img/icon/send-image.svg'),
    text: t('coordinator.send_image'),
  },
];
const selectFile = (type) => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    if (type === 'image') {
      input.accept = 'image/*';
      mediaType.value = 'image';
    } else {
      input.accept = 'video/*';
      mediaType.value = 'video';
    }

    input.onchange = (e) => {
      const file = e.target.files[0];
      resolve(file);
    };

    input.oncancel = () => {
      resolve(null);
    };
    setTimeout(() => {
      input.onblur = () => {
        resolve(null);
      };
    }, 0);

    input.click();
  });
};

const handleClickUpload = async (type) => {
  try {
    const file = await selectFile(type);
    if (file) {
      isSendFile.value = true;
      customRequest(file);
    } else {
      isSendFile.value = false;
    }
  } catch (error) {
    console.error('Error during file selection', error);
  }
};

watch(userName, (newValue) => {
  hasInput.value = newValue.trim() !== '';
});
const handleSendMessages = () => {
  if (userName.value) {
    let mess = {
      receiverId: userIdSelect.value.userId,
      receiverType: 1,
      messageType: isSendFile.value ? 'file' : 'text',
      content: userName.value,
      mediaType: mediaType.value,
      url: urlImage.value,
      length: seconds.value,
      forwarded: false,
    };
    dispatch('coordinator/sendMessages', mess).then((data) => {
      userName.value = '';
      isSendFile.value = null;
      (mediaType.value = null), (urlImage.value = null);
      stopTime();
    });
  }
  if (isRecording.value) {
    sendRecording();
  }
};

const customRequest = async (file) => {
  const fmData = new FormData();
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  fmData.append('file', file);
  fmData.append('rootPath', 'image');
  try {
    const res = await DataService.upload(
      ConstantAPI.upload_service.UPLOAD,
      fmData,
      config
    );
    if (Number(res.code) === 200) {
      userName.value = ' ';
      urlImage.value = res.data.url;
      handleSendMessages();
    }
  } catch (err) {
    onError({ err });
  }
};

const formattedTime = computed(() => {
  const formattedMinutes = String(minutes.value).padStart(2, '0');
  const formattedSeconds = String(seconds.value).padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
});
const startTime = () => {
  if (intervalId) return;

  intervalId = setInterval(() => {
    if (seconds.value === 59) {
      seconds.value = 0;
      minutes.value += 1;
    } else {
      seconds.value += 1;
    }
  }, 1000);
};

const stopTime = () => {
  clearInterval(intervalId);
  intervalId = null;
  seconds.value = 0;
  minutes.value = 0;
  isRecording.value = false;
};

const startRecording = () => {
  startTime();
  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((stream) => {
      if (mediaRecorder.value && mediaRecorder.value.state === 'recording') {
        mediaRecorder.value.stop();
      }
      const audioContext = new AudioContext();
      analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      analyser.fftSize = 256;
      audioChunks.value = [];
      analyzeVolume();

      mediaRecorder.value = new MediaRecorder(stream);
      mediaRecorder.value.ondataavailable = (event) => {
        audioChunks.value.push(event.data);
      };
      mediaRecorder.value.onstop = () => {
        const audioBlob = new Blob(audioChunks.value, { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        audioURL.value = audioUrl;
        audioFile.value = new File([audioBlob], 'recording.mp3', {
          type: 'audio/mpeg',
        });
        audioChunks.value = [];
        isSendFile.value = true;
        mediaType.value = 'audio';
        if (isRecording.value) {
          customRequest(audioFile.value);
        }
        if (audioContext) {
          audioContext.close();
        }
      };
      mediaRecorder.value.start();
      isRecording.value = true;
    })
    .catch((error) => {
      console.error('Lỗi khi yêu cầu quyền truy cập microphone:', error);
    });
};

const analyzeVolume = () => {
  const dataArray = new Uint8Array(analyser.frequencyBinCount);
  const analyze = () => {
    setTimeout(() => {
      analyser.getByteFrequencyData(dataArray);
      const averageVolume =
        dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
      volumeArray.value.push(Math.floor(averageVolume));
      if (volumeArray.value.length > 10) {
        volumeArray.value.shift();
      }
      analyze();
    }, 200);
  };
  analyze();
};
const sendRecording = () => {
  if (mediaRecorder.value && isRecording.value) {
    mediaRecorder.value.stop();
    volumeArray.value = Array(10).fill(null);
  }
};
const stopRecording = () => {
  if (mediaRecorder.value && isRecording.value) {
    mediaRecorder.value.stop();
    isRecording.value = false;
    volumeArray.value = Array(10).fill(null);
    seconds.value = 0;
    minutes.value = 0;
  }
};
const handleClose = () => {
  dispatch('coordinator/handleSetComponent', null);
};
</script>

<style lang="scss">

.overLayDropDown{
  z-index: 1222 ;
}

</style>
